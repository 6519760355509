import React from 'react'

const Details = () => {
    return (
        <section className="services-details-area ptb-100" style={{backgroundColor:'white'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                       
                        <div className="services-details-desc">
                            <h1> Features </h1>
                            
                                <div className="content">          
                                     <h3>Patient Registration </h3>
                                        <ul>
                                            <li>Enter Complete patient demographics with image.</li>
                                            <li>Bed Allocation in the required hospital service.</li>
                                            <li>Addition of Insurance and Corporate information of patient.</li>
                                            <li>Automated MR # and Admission # generation.</li>
                                            <li>New Born Registration.</li>
                                        </ul>
                                </div>

                           
                                <div className="content">  
                                    <h3>Discharge Summary</h3>
                                        <ul>
                                            <li>Customizable Discharge template.</li>
                                            <li>Comprehensive treatment summary at the time of Patient Discharge.</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Patient Record Management</h3>
                                        <ul>
                                            <li>View records of all registered Patient.</li>
                                            <li>View list and details of Insurance and Corporate patient individually.</li>
                                            <li>Get your record printed.</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Productivity Analysis </h3>
                                        <ul>
                                            <li>Analyze each employee productivity in the selected duration.</li>
                                        </ul>
                                </div>                                  
                        </div>
                    </div>    
                            
                </div>
            </div>
        </section>
    )
}

export default Details