import React from 'react'
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
// import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../assets/images/projects/projects-details1.jpg'
import details2 from '../../assets/images/projects/projects-details2.jpg'
import details3 from '../../assets/images/main-banner/main-logo1.png'
import details4 from '../../assets/images/projects/projects-details4.jpg'
import details5 from '../../assets/images/projects/projects-details5.jpg'
import pvideo   from '../../assets/video/Panacea.mp4'
import client4 from '../../assets/images/testimonials/client4.jpg'
import {Link} from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};


// const CaseStudiesDetailsContent = () => {
//     const [display, setDisplay] = React.useState(false);

//     React.useEffect(() => {
//         setDisplay(true);
//     }, [])
const Banner = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    // // Owl Carousel
    // const [display, setDisplay] = React.useState(false);

    // React.useEffect(() => {
    //     setDisplay(true);
    // }, [])

     // Owl Carousel
     const [display, setDisplay] = React.useState(false);

     React.useEffect(() => {
         setDisplay(true);
     }, [])
 

return (
    <React.Fragment>
        {/* If you want to change the video need to update videoID */}
        {/* <ModalVideo 
            channel='youtube' 
            isOpen={!isOpen} 
            videoId='bk7McNUjWgw' 
            onClose={() => setIsOpen(!isOpen)} 
        /> */}



{display ? <OwlCarousel 
    className="home-slides owl-carousel owl-theme"
    {...options}
> 

    <div className="main-banner-item item-bgN1">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">NARRO</span>
                        <h1>Patient Registration System</h1>
                        <p>Efficient patient care starts with accurate information.</p>
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="main-banner-item item-bgN2">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title"></span>
                        <h1>Integrable to any HMIS</h1>
                        
                        <p>Patient information system can be plug in to any Hospital Mangement Information System. </p>
                        {/* <p>CENTER TO NATIONAL TRANSFUSION SERIVCE </p> */}
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   

    <div className="main-banner-item item-bgN4">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title"></span>
                        <h1>Positive Photo Identification & Verification </h1>
                        <p>Matching a patient to appropriately intended interventions and communicating information about the patient's identity accurately and reliably throughout the continuum of care.</p>
                        <quote>Lauren Riplinger, Jordi Piera-Jiménez, and Julie Pursley Dooling</quote>
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="main-banner-item item-bgN3">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title"></span>
                        <h1>Design your forms </h1>
                            <h1>as per your need</h1>
                        <p>Dynamic customization allows to generate admission and discharge forms. </p>
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="main-banner-item item-bgN5">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title"></span>
                        <h1>Bed Allocation Management</h1>
                        <p>Real time updated information with separate color codes for bed availability</p>
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="main-banner-item item-bgN6">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title"></span>
                        <h1>Electronic Patient Record Management</h1>
                        <p>Maintain comprehensive summary of patient’s treatment </p>
                        
                        <div className="btn-box">
                        <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
   


</OwlCarousel> : ''}
</React.Fragment>
)
}

export default Banner